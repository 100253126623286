import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NormaPintura } from '../../../model/controles/norma-pintura/norma-pintura';
import { NormaPinturaConsultaDTO } from '../../../dto/norma-pintura/normaPinturaConsultaDTO';
import { NormaPinturaRequisito } from '../../../model/controles/norma-pintura/normaPinturaRequisito';

@Injectable({
  providedIn: 'root'
})
export class NormaPinturaService {

  private url = `${environment.url}/controles/norma-pintura`;

  constructor(
    private httpClient: HttpClient
  ) { }

  public cadastrarNormaPintura(normaPintura: FormData): Observable<FormData> {
    return this.httpClient.post<FormData>(this.url, normaPintura)
  }

  public atualizarNormaPintura(normaPintura: FormData): Observable<FormData> {
    return this.httpClient.put<FormData>(this.url, normaPintura)
  }

  // public atualizarNormaPinturaRequisitos(normaPintura: FormData, idNormaPintura: number): Observable<FormData> {
  //   return this.httpClient.put<FormData>(`${this.url}/atualizar/requisito/${idNormaPintura}`, normaPintura)
  // }

  public incluirArquivoNormaPintura(idNormaPintura: number, formData: FormData): Observable<any>  {
    return this.httpClient.put<NormaPintura>(`${this.url}/file/${idNormaPintura}`, formData)
  }

  public incluirRequisito(idNormaPintura: number, normaPinturaRequisito: NormaPinturaRequisito):Observable<any>  {
    return this.httpClient.put<NormaPintura>(`${this.url}/requisito/${idNormaPintura}`, normaPinturaRequisito)
  }

  public alterarRequisito(requisito: NormaPinturaRequisito): Observable<any> {
    return this.httpClient.put<NormaPintura>(`${this.url}/requisito`, requisito);
  }

  public consultaRequisitoPorId(idRequisito: any):Observable<any> {
    return this.httpClient.get<NormaPintura>(`${this.url}/requisito/${idRequisito}`);
  }

  public deletarRequisitoNormaPintura(idNormaPintura: number, idRequisito: number): Observable<NormaPintura> {
    return this.httpClient.delete<NormaPintura>(`${this.url}/requisito/${idNormaPintura}/${idRequisito}`)
  }

  public deletarArquivoNormaPintura(idNormaPintura: number): Observable<any>  {
    return this.httpClient.delete<NormaPintura>(`${this.url}/file/${idNormaPintura}`)
  }

  public consultarNormaPinturaPaginada(paginaAtual: number, itemPorPagina: number): Observable<NormaPinturaConsultaDTO[]> {
    return this.httpClient.get<NormaPinturaConsultaDTO[]>(`${this.url}/${paginaAtual}/${itemPorPagina}`)
  }

  public consultarPorIdNormaPintura(idNormaPintura: number): Observable<NormaPinturaConsultaDTO> {
    return this.httpClient.get<NormaPinturaConsultaDTO>(`${this.url}/${idNormaPintura}`)
  }

  public consultarPorMergeNormaPintura(valorDeBusca: string): Observable<NormaPinturaConsultaDTO[]> {
    return this.httpClient.get<NormaPinturaConsultaDTO[]>(`${this.url}/consulta/${valorDeBusca}`)
  }
}
